import { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, AutoSizer, ScrollSync } from "react-virtualized";
import { RemoveButton } from "../../../../../../components/Buttons";
import Icon from "../../../../../../components/Icon";
import styles from "./RenderFieldArray.module.scss";

function renderFieldArray(HeaderComponent, FieldComponent) {
  const RenderFieldArray = ({
    fields,
    meta,
    requiredFieldNames,
    defaultValues,
    selectedLanguages,
    removeTranslation,
    setTermsToDelete,
    ...rest
  }) => {
    useEffect(() => {
      if (fields.length === 0) fields.push(defaultValues || {});
    }, [fields, defaultValues, selectedLanguages]);

    const handleRemoveTranslation = (rowIndex) => {
      fields.remove(rowIndex);
      const [sourceSegment] = Object.values(fields.get(rowIndex));
      setTermsToDelete(sourceSegment);
      removeTranslation(rowIndex);
    };

    const headerCellRenderer = ({ columnIndex, key, style }) => {
      return (
        <div key={key} style={style}>
          <HeaderComponent
            languageCode={selectedLanguages[columnIndex]}
            isSource={columnIndex === 0}
            selectedLanguages={selectedLanguages}
            key={key}
            style={style}
            {...rest}
          />
        </div>
      );
    };

    const removeHeaderRenderer = ({ rowIndex, style }) => {
      return (
        <div style={{ ...style, width: "50px" }}>
          <RemoveButton onClick={() => handleRemoveTranslation(rowIndex)} />
        </div>
      );
    };

    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
      const languageCode = selectedLanguages[columnIndex];
      const isLastColumn = columnIndex === selectedLanguages.length - 1;
      return (
        <Fragment key={key}>
          <div
            style={{
              ...style,
              width: isLastColumn ? style.width - 50 : style.width,
            }}
          >
            <FieldComponent
              name={`translations[${rowIndex}]`}
              languageCode={languageCode}
              meta={meta}
              {...rest}
              addField={() => fields.push(defaultValues || {})}
              swapField={fields.swap}
              disableRemove={fields.length === 1}
            />
          </div>
          {isLastColumn && (
            <div
              style={{
                ...style,
                width: 50,
                left: style.left + style.width - 50,
              }}
            >
              {removeHeaderRenderer({
                rowIndex,
              })}
            </div>
          )}
        </Fragment>
      );
    };

    const getColumnWidth =
      (width, hasScroll) =>
      ({ index }) => {
        const minColumnWidth = 200;
        // subtract 65px for scrollbar(15px) and last column remove button(50px)
        const calculatedWidth = Math.floor(
          (width - (hasScroll ? 65 : 50)) / selectedLanguages.length
        );
        const isLastColumn = index === selectedLanguages.length - 1;
        const columnWidth = Math.max(calculatedWidth, minColumnWidth);
        return isLastColumn ? columnWidth + 50 : columnWidth;
      };

    const addField = (e) => {
      e.preventDefault();
      const index = fields.length - 1;
      const lastField = fields.get(index);

      // If no values in the last field or requiredFieldNames are missing, don't add a field
      if (
        Object.keys(lastField).length === 0 ||
        (requiredFieldNames &&
          requiredFieldNames.some((name) => !lastField.hasOwnProperty(name)))
      ) {
        return;
      }

      fields.push(defaultValues || {});
    };

    const rowHeight = 50;

    const getGridHeight = () => {
      const calculatedHeight = fields.length * rowHeight + 50; // 50 for the header
      // 460 is the remaining height other than grid header and content
      const vhHeight = window.innerHeight - 460;
      return Math.min(calculatedHeight, vhHeight);
    };

    const gridHeight = getGridHeight();

    const hasScroll = fields.length * rowHeight > gridHeight;

    return (
      <>
        <ScrollSync>
          {({ onScroll, scrollLeft }) => (
            <div style={{ height: gridHeight }}>
              <AutoSizer>
                {({ width, height }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width,
                    }}
                  >
                    <div className={styles.scrollBarLeft} style={{ width }}>
                      <Grid
                        cellRenderer={headerCellRenderer}
                        columnCount={selectedLanguages.length}
                        columnWidth={getColumnWidth(width, hasScroll)}
                        height={50}
                        rowCount={1}
                        rowHeight={rowHeight}
                        width={width}
                        onScroll={onScroll}
                        scrollLeft={scrollLeft}
                        className={styles.removeScrollBar}
                      />
                      <Grid
                        cellRenderer={cellRenderer}
                        columnCount={selectedLanguages.length}
                        columnWidth={getColumnWidth(width, hasScroll)}
                        height={height - 50}
                        rowCount={fields.length}
                        rowHeight={rowHeight}
                        width={width}
                        onScroll={onScroll}
                        scrollLeft={scrollLeft}
                      />
                    </div>
                  </div>
                )}
              </AutoSizer>
            </div>
          )}
        </ScrollSync>
        <button onClick={addField} className={styles.newButton}>
          Add New
        </button>
      </>
    );
  };

  RenderFieldArray.propTypes = {
    fields: PropTypes.object.isRequired,
    requiredFieldNames: PropTypes.arrayOf(PropTypes.string),
    defaultValues: PropTypes.object,
    selectedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
    meta: PropTypes.shape({
      submitFailed: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
  };

  return RenderFieldArray;
}

export default renderFieldArray;
